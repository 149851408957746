import { dataProvider } from "data";
import { UPDATE_AFFLIATE_PROFILE_API } from "../constants/Affliates.api";
import { apiMethods } from "data/api.constants";

export const updateAffiliateProfileCall = ({
  updatedValues,
  affiliateUuid,
}) => {
  return dataProvider.custom_request(
    UPDATE_AFFLIATE_PROFILE_API,
    apiMethods.POST,
    { updated_values: updatedValues, affiliate_uuid: affiliateUuid }
  );
};
